import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Button, Heading, Paragraph } from '@hexa-ui/components';

import { Error404 } from '../../assets/Error404';
import { routes } from '../../router/routes';
import { Error404Container } from './Error404Page.styles';

const Error404Page = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  return (
    <Error404Container>
      <Error404 />
      <Heading size="H2" css={{ margin: '40px 0 20px' }}>
        {formatMessage({ id: 'error404.heading' })}
      </Heading>
      <Paragraph css={{ marginBottom: '16px' }}>
        {formatMessage({ id: 'error404.description' })}
      </Paragraph>
      <Button size="large" onClick={() => navigate(routes.HOME_PAGE)}>
        {formatMessage({ id: 'error404.home' })}
      </Button>
    </Error404Container>
  );
};

export default Error404Page;
