import { PropsWithChildren } from 'react';

import { Grid } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import { useSidebar } from 'admin-portal-shared-services';

import { useSidebarItems } from '../hooks/useSidebarItems';

const AppBarConfigProvider = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const { visibleItems } = useSidebarItems();

  useSidebar({
    items: [...visibleItems],
    utils: [],
  });

  return (
    <StyledGrid type="fluid" sidebar>
      {children}
    </StyledGrid>
  );
};

const StyledGrid = styled(Grid.Container, {
  paddingTop: '$4',
  '@desktopSmall': {
    paddingTop: 0,
  },
});

export default AppBarConfigProvider;
