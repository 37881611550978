import { QueryClientProvider } from '@tanstack/react-query';

import { EnvConfig, EnvProvider } from './components';
import { queryClient } from './config/reactQuery';
import { IntlProvider } from './i18n';
import Router from './router/Router';
import AppBarConfigProvider from './utils/AppBarConfigProvider';

export default function App(props: EnvConfig) {
  return (
    <EnvProvider env={props}>
      <IntlProvider>
        <AppBarConfigProvider>
          <QueryClientProvider client={queryClient}>
            <Router />
          </QueryClientProvider>
        </AppBarConfigProvider>
      </IntlProvider>
    </EnvProvider>
  );
}
