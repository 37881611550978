import { useHasPermission } from 'admin-portal-shared-services';

export const useAppRoles = () => {
    const isSuperAdmin = useHasPermission('BeesOneAdminTool.App.SuperAdmin');
    const isViewer = useHasPermission('BeesOneAdminTool.App.Viewer');
    const isManager = useHasPermission('BeesOneAdminTool.App.Manager');

    return {
        isSuperAdmin,
        isViewer,
        isManager,
    };
};
