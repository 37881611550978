import { generatePath } from 'react-router-dom';

export const BASE_PATH = '/admin';

const HOME_PAGE = `${BASE_PATH}`;
const NEW_APP = `${BASE_PATH}/app/new`;
const APP_DETAILS_PAGE = `${BASE_PATH}/app/:appId`;

const CATEGORIES_AND_TAGS = `${BASE_PATH}/categories-and-tags`;
const CATEGORIES_LIST = `${CATEGORIES_AND_TAGS}/categories`;
const NEW_CATEGORY = `${CATEGORIES_AND_TAGS}/categories/new`;
const EDIT_CATEGORIES = `${CATEGORIES_AND_TAGS}/categories/:id`;
const BULK_TRANSLATION = `${CATEGORIES_AND_TAGS}/bulk-translation`;

const TAGS_LIST = `${CATEGORIES_AND_TAGS}/tags`;
const NEW_TAG = `${CATEGORIES_AND_TAGS}/tags/new`;
const EDIT_TAGS = `${CATEGORIES_AND_TAGS}/tags/:id`;

export const routes = {
  HOME_PAGE,
  NEW_APP,
  APP_DETAILS_PAGE,
  CATEGORIES_AND_TAGS,
  BULK_TRANSLATION,
  CATEGORIES: {
    LIST: CATEGORIES_LIST,
    NEW: NEW_CATEGORY,
    EDIT: EDIT_CATEGORIES,
  },
  TAGS: {
    LIST: TAGS_LIST,
    NEW: NEW_TAG,
    EDIT: EDIT_TAGS,
  },
};

export { generatePath };
