import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },
  sidebar: {
    applications: 'Applications',
    categoriesAndTags: 'Categories and tags',
  },
  languages: {
    english: 'English',
    spanish: 'Spanish',
    portuguese: 'Portuguese',
    korean: 'Korean',
  },
  error404: {
    heading: 'Mmm... Looks like this page is gone',
    description: "It looks like this page wasn't found or doesn't exist.",
    home: 'Home',
  },
  error403: {
    heading: 'Hmm... Access denied!',
    description: "It looks like you don't have permission to access this page.",
    home: 'Home',
  },
  appsPage: {
    title: 'BEES One Apps',
    table: {
      appName: 'App name',
      responsibleTeam: 'Responsible team',
      groupProductManager: 'Group product manager',
      productManager: 'Product manager',
      engineeringManager: 'Engineering manager',
      status: 'Tool Library availability',
      errorMessage: "Content couldn't be loaded.",
      emptyMessage: 'No apps found.',
      emptyValue: 'Not informed',
    },
    buttons: {
      createNewApp: 'Create new app',
    },
    filters: {
      filterBy: 'Filter by',
      searchPlaceholder: 'Search apps',
      clearFilters: 'Clear all',
      cancel: 'Cancel',
      applyFilters: 'Apply',
      category: 'Category',
      responsibleTeam: 'Responsible team',
      status: 'Tool Library availability',
      tags: 'Tags',
      filteredBy: 'Filtered by',
    },
  },
  statusBase: {
    available: 'Visible',
    unavailable: 'Not visible',
    availableInfo: 'Visible on Tool Library.',
    unavailableInfo: 'Not visible on Tool Library.',
  },
  pagination: {
    pageSizeOptions: 'Items per page: {options}',
    of: 'of',
  },
  createAppPage: {
    title: 'Create new app',
    toast: {
      success: 'New app created.',
      error: 'Something went wrong. Try again.',
      validationError:
        'Required fields must be filled and valid. Review them to continue.',
    },
  },
  appDetailsPage: {
    buttons: {
      save: 'Save changes',
      discard: 'Discard changes',
    },
    toggles: {
      availableLabel: 'Visible on Tool Library',
      enable: {
        success: 'This app is now visible on Tool Library.',
        error: 'Unable to make this app visible on Tool Library.',
      },
      disable: {
        success: 'This app is now not visible on Tool Library.',
        error: 'Unable to make this app not visible on Tool Library.',
      },
    },
    emptyStates: {
      notInformed: 'Not informed.',
    },
    tabs: {
      general: 'General',
      content: 'Content',
      audience: 'Audience',
      platformDetails: 'Platform details',
      ownership: 'Ownership',
      supportChannels: 'Support channels',
      dataPrivacy: 'Data privacy',
    },
    general: {
      title: 'General',
      details: 'Details',
      refresh: 'Refresh',
      required: 'Required field.',
      inputs: {
        appUrl: 'App URL',
        appUrlHint: 'Example: https://one.bees.com/URL',
        appUrlPlaceholder: 'Enter app URL',
        appUrlMaxLength: 'App URL must be less than 45 characters.',
        appId: 'App ID',
        appIdHint: 'The app ID is in the config.json file.',
        publishedIn: 'Published in',
        publishedInHint:
          'Countries in which the app is already published on BEES One catalog.',
        allCountries: 'All countries',
        loadingError: 'Unable to load the countries. Try refreshing the page.',
      },
      categoryAndTags: 'Category and tags',
      categories: {
        label: 'Category',
        placeholder: 'Select a category',
        loadingError: 'Unable to load the categories. Try refreshing the page.',
        emptyState: 'There is no category selected for this application.',
      },
      tags: {
        label: 'Tags',
        displayText: 'tags selected',
        selected: 'Selected tags',
        loadingError: 'Unable to load the tags. Try refreshing the page.',
        emptyState: 'There are no tags selected for this application.',
      },
      images: {
        title: 'Images',
        description:
          'Upload up to 5 images that will show up on your product details page.',
        message:
          'We support PNG and JPG files. Maximum size of 20MB per file, and dimensions up to 1195x726px.',
        select: 'Select file',
        tryAgain: 'Try again',
        fileTooBig: 'File size is too big',
        fileResolutionInvalid: 'Resolution is not valid',
        fileNotAllowed: 'File type not allowed',
        emptyState: 'There are no images uploaded for this application.',
      },
    },
    supportChannels: {
      title: 'Support channels',
      description:
        'Possible ways users can request support regarding this application.',
      inputs: {
        internalUsers: 'Internal channel or email',
        internalUsersHint:
          'For internal users only. Example: Slack channel name or support email.',
        externalUsers: 'External channel or email',
        externalUsersHint:
          'Support channel for external users only. Example: Slack channel name or support email.',
        documentation: 'Internal documentation page',
        documentationHint:
          'For internal users only. Example: Confluence documentation page.',
        channelNamePlaceholder: 'Enter channel name or email',
        linkPlaceholder: 'Enter link',
      },
      optionalText: '(optional)',
    },
    dataPrivacy: {
      title: 'Data privacy',
      sharing: {
        title: 'Sharing data with third parties',
        question:
          'In the processing or functioning of the application, does your app transfer or disclose any kind of collected user data to third-party entities?',
        answer:
          'Please consider all types of data transmission between our platform and any external organizations, including but not limited to, analytics providers, ad networks, CRM systems, or social networks.',
      },
      reliance: {
        title: 'Reliance on external data sources',
        question:
          'Does your application depend on any external data sources to function effectively, or to enhance the user experience?',
        answer:
          'This can include but is not limited to APIs, databases, cloud services, or any other external data suppliers that our application retrieves, updates, or interacts with during its operation.',
      },
      partner: {
        title: 'Sensitive partner information',
        question:
          'Does your application store, process or handle any sensitive information relating to our 3P Partners?',
        answer:
          'This could include, but is not limited to, transactional data (orders, volume, invoice, price, coverage area or POCs, etc), Commercial Strategy (marketing, promotions, rewards, expansion, audience segmentation, client lists, etc), Behavioral data (trends, customer behavior, etc).',
        description:
          'Please consider any data that, if exposed inadvertently, could potentially harm our 3P Partners.',
      },
      personal: {
        title: 'Personal data processing',
        question:
          'From a Data Privacy perspective, which types of personal data does your application process*?',
        answer:
          'Personal data: Any data that identifies or can identify a person, such as name, address, contact details (cellphone/e-mail), bank details, date, and place of birth, IP address, location via GPS, etc.',
        description:
          '*”Processing” is any activity that uses personal data such as: analysis, collection, classification, usage, access, reproduction, storage, elimination, among others.',
      },
      sensitiveData: {
        title: 'Sensitive personal data processing',
        question:
          'From a Data Privacy perspective, does your application process sensitive personal data*?',
        answer:
          'Sensitive personal data: Gender, race or ethnic origin, sexual orientation, political opinion, trade union membership, religious or philosophical beliefs, biometric, health, and others defined as such.',
        description:
          '*”Processing” is any activity that uses personal data such as: analysis, collection, classification, usage, access, reproduction, storage, elimination, among others.',
      },
      checkBoxValidation: {
        required: 'Required field.',
      },
      output: {
        true: 'Yes',
        false: 'No',
        empty: 'No answer provided',
      },
    },
    ownership: {
      title: 'Ownership',
      details: 'Details',
      productManager: 'Product manager',
      engineeringManager: 'Engineering manager',
      groupProductManager: 'Group product manager',
      softwareArchitect: 'Software architect',
      authorizationAndAccess: 'Authorization and access',
      inputs: {
        responsibleTeam: 'Responsible team',
        name: 'Name',
        email: 'Email',
        authorizationManagers: 'Authorization managers',
        accessApprovers: 'Access approvers',
        separateWithCommasHint: 'Separate multiple emails with commas.',
        namePlaceholder: 'Enter name',
        emailPlaceholder: 'Enter email',
        emailsPlaceholder: 'Enter emails',
        teamNamePlaceholder: 'Enter the team name',
      },
      optionalText: '(optional)',
    },
    platformDetails: {
      title: 'Platform details',
      repositoryEnvironments: 'Environments and repositories',
      repositoryLabel: 'Repository links',
      repositoryDescription: 'Separate multiple links with commas.',
      applicationEnvironments: 'Application environment',
      relatedApps: 'Related apps',
      addRelatedApps: 'Add related app',
      repositoriesEmpty: 'Not informed.',
      relatedAppsEmpty: 'This application has no related apps.',
      environmentsEmpty:
        'There are no environments selected for this application.',
      actionsButton: {
        goBack: 'Go Back',
        confirm: 'Save',
        delete: 'Delete',
      },
      relatedAppsModal: {
        title: 'Add related app',
        description: 'Select the apps that are related to your app.',
        selectedApps: 'Related apps',
        appsSelected: 'apps selected',
        applications: 'Applications',
      },
      removeRelatedAppModal: {
        title: 'Remove related app?',
        description: 'The app will be removed from the related apps list.',
      },
    },
    content: {
      title: 'Content',
      required: 'Required field.',
      englishVersion: 'English version (default)',
      languageChangeToast: 'Edit language was changed to {language}.',
      viewingIn: 'Viewing in',
      catalogPreview: {
        title: 'Catalog preview',
        details: 'Details',
        iconLabel: 'Icon',
        iconColor: 'Icon color',
        iconPlaceholder: 'Select option',
        hexColor: 'HEX color code',
        hexColorPlaceholder: 'Enter code',
        optionalText: '(optional)',
      },
      appInformation: {
        title: 'App information',
        titleTranslated: 'Translated app information',
        appName: 'App name',
        appNamePlaceholder: 'Enter app name',
        appNameHint: 'Displayed both in the catalog and details page.',
        appNameMaxLength: 'App name must be less than 80 characters.',
        shortDescription: 'Short description',
        shortDescriptionMaxLength:
          'Short description must be less than 150 characters.',
        shortDescriptionPlaceholder: 'Enter short description',
        fullDescription: 'Full description',
        fullDescriptionPlaceholder: 'Enter full description',
        fullDescriptionMinLength:
          'Full description must be at least 200 characters.',
        fullDescriptionMaxLength:
          'Full description must be less than 600 characters.',
        fullDescriptionHint:
          'Displayed in the app details page. Minimum of 200 characters.',
        appDescriptionEmpty: 'Short description not informed.',
        notInformed: 'Not informed.',
      },
      languageModal: {
        title: 'Save changes before switching languages?',
        description:
          'If you switch languages without saving, all changes will be lost.',
        saveButton: 'Save and switch',
        discardButton: 'Discard and switch',
      },
      englishVersionModal: {
        title: 'English content is required before switching',
        description:
          'Please complete and save the required content in English before switching to other language pages.',
        okay: 'Okay',
      },
      features: {
        modal: {
          deleteTitle: 'Delete feature?',
          goBack: 'Go back',
          deleteButton: 'Delete',
          message:
            'This feature will be deleted in all languages available in this application.',
        },
        title: 'Features',
        details:
          'Describe the main application features. Minimum 1 and maximum 5.',
        feature: 'Feature',
        addNew: 'Add new feature',
        deleteFeature: 'Delete',
        inputName: 'Enter feature name',
        inputDescription: 'Enter feature description',
        labelName: 'Name',
        labelDescription: 'Description',
        labelIcon: 'Icon',
        deleteToast: 'Feature deleted.',
        nameMaxLength: 'Name must be less than 150 characters.',
        descriptionMinLength: 'Description must be at least 50 characters.',
        descriptionMaxLength: 'Description must be less than 400 characters.',
        featuresEmpty: 'There are no features specified for this application.',
      },
    },
    audience: {
      title: 'Audience',
      refresh: 'Refresh',
      required: 'Required field.',
      userTypes: {
        title: 'User types',
        details:
          'Type of user this application is intended for. Select at least 1 type.',
        detailsViewer: 'Type of user this application is intended for.',
        loadingError: 'Unable to load user types. Try refreshing the page.',
        emptyMessage: 'There are no user types selected for this application.',
      },
      permissionGroups: {
        title: 'Permission groups',
        details:
          'Groups of permission from Identity Management which may access this application.',
        detailsViewer:
          'Groups from Identity Management to which this application is intended for.',
        label: 'Permission groups',
        selectedGroups: 'Selected groups',
        displayText: 'groups selected',
        loadingError:
          'Unable to load permission groups. Try refreshing the page.',
        optionalText: '(optional)',
        emptyMessage:
          'There are no permission groups selected for this application.',
      },
    },
    inputValidation: {
      required: 'Required field.',
      email: 'Enter a valid email.',
      emails: 'Enter valid emails.',
      url: 'Enter a valid URL.',
    },
    saveToast: 'Changes saved.',
    discardToast: 'Changes discarded.',
    errorToast: 'Something went wrong. Try again.',
    errorToastFileType:
      'Image {fileNumber} is not in the correct format. Please upload the correct file type.',
    saveChangesModal: {
      title: 'Save changes?',
      description: 'All changes made will be applied.',
      save: 'Save',
      cancel: 'Cancel',
    },
    leaveTabModal: {
      title: 'Leave without saving?',
      description:
        'If you switch tabs without saving changes, all changes made will be lost.',
      leave: 'Yes, leave',
      stay: 'No, stay',
    },
    alertRequiredFields:
      'Please ensure all the required fields are filled correctly and within the character limit.',
    alertFormValidation:
      'Please ensure all the fields are filled correctly and within the character limit.',
    savingChanges: 'Saving changes...',
  },
  error: {
    tryAgain: 'Try again',
    loadingError: "Content couldn't be loaded.",
  },
  categoriesAndTags: {
    title: 'Categories and tags',
    common: {
      loading: 'Loading...',
      saving: 'Saving...',
      language: 'Language',
      discardToast: 'Changes discarded.',
      translation: 'Translation',
    },
    new: {
      button: { save: 'Create', cancel: 'Cancel' },
    },
    edit: {
      createdOn: 'Created on',
      lastUpdatedOn: 'Last updated on',
      button: {
        save: 'Save changes',
        cancel: 'Discard changes',
      },
      deleteModal: {
        button: {
          delete: 'Yes, delete',
          discard: 'No, go back',
        },
      },
    },
    bulkTranslationPage: {
      loadingError: "Content couldn't be loaded.",
      title: 'Bulk translate to {language}',
      breadcrumb: 'Bulk translate',
      cancelButton: 'Cancel',
      tryAgain: 'Try again',
      confirmButton: 'Save',
      name: {
        categories: 'Category name',
        tags: 'Tag name',
      },
      translation: {
        categories: 'Category name',
        categoriesDescriptions: 'Category description',
        tags: 'Tag translation',
      },
      placeholder: {
        categories: 'Enter category name',
        categoriesDescriptions: 'Enter category description',
        tags: 'Enter tag name',
      },
      toastMessages: {
        unable: 'Unable to load categories. Try again.',
        error: 'Unable to save translation. Try again.',
        success: 'Translation successfully saved.',
        discard: 'Changes discarded.',
      },
      modal: {
        title: 'Bulk translate',
        language: 'Language',
        error: 'You must select a language.',
        cancelButton: 'Cancel',
        select: 'Select',
        categories: {
          description:
            'Select a language to translate all existing categories at once.',
          confirmButton: 'Translate categories',
        },
        tags: {
          description:
            'Select a language to translate all existing tags at once.',
          confirmButton: 'Translate tags',
        },
      },
    },
    categories: {
      tab: 'Categories',
      searchPlaceholder: 'Search by category name',
      createButton: 'Create category',
      bulkTranslation: 'Bulk translate',
    },
    tags: {
      tab: 'Tags',
      searchPlaceholder: 'Search by tag name',
      createButton: 'Create tag',
    },
    table: {
      categories: 'Category name',
      tags: 'Tag name',
      translatedTo: 'Translated to',
      createdOn: 'Created on',
      updatedOn: 'Last updated on',
      numberOfLanguages: '{number} languages',
    },
  },
  leaveWithoutSavingModal: {
    title: 'Leave without saving?',
    description:
      'If you switch tabs without saving changes, all changes made will be lost.',
    leave: 'Yes, leave',
    stay: 'No, stay',
  },
  categories: {
    common: {
      input: {
        placeholder: 'Enter category name',
        translationPlaceholder: 'Enter translated name',
        label: 'Category name',
        hint: 'Default name must be in English.',
        required: 'You must enter a category name.',
      },
      inputDescription: {
        placeholder: 'Enter category description',
        translationPlaceholder: 'Enter translated description',
        label: 'Category description',
        hint: 'Default description must be in English.',
        required: 'You must enter a category description.',
      },
      info: 'Category information',
      description:
        'Enter a name for the category in each of the following languages:',
    },
    new: {
      title: 'Create new category',
      toast: {
        success: 'Category successfully created.',
        error: 'Unable to create category. Try again.',
        statusError:
          'No connection found. Check your internet connection and try again.',
      },
    },
    edit: {
      title: 'Edit category',
      button: {
        delete: 'Delete category',
      },
      toast: {
        success: 'Category successfully saved.',
        error: 'Unable to save category. Try again.',
        statusError:
          'No connection found. Check your internet connection and try again.',
      },
      deleteModal: {
        title: 'Delete category?',
        description:
          'This action will affect all applications where this category appears. You cannot undo this action.',
        toast: {
          success: 'Category successfully deleted.',
          error: 'Unable to delete category. Try again.',
        },
      },
      deleteErrorModal: {
        title: 'Unable to delete category',
        description:
          'Unassign the category from all applications before deleting it.',
        button: {
          confirm: 'Got it',
        },
      },
    },
  },
  tags: {
    new: {
      title: 'Create new tag',
      button: { save: 'Create', cancel: 'Cancel' },
      toast: {
        success: 'Tag successfully created.',
        error: 'Unable to create tag. Try again.',
        statusError:
          'No connection found. Check your internet connection and try again.',
      },
    },
    common: {
      info: 'Tag information',
      description:
        'Enter a name for the tag in each of the following languages:',
      input: {
        placeholder: 'Enter tag name',
        translationPlaceholder: 'Enter translated name',
        label: 'Tag name',
        hint: 'Default name must be in English.',
        required: 'You must enter a tag name.',
      },
    },
    edit: {
      title: 'Edit tag',
      button: {
        delete: 'Delete tag',
      },
      toast: {
        success: 'Tag successfully saved.',
        error: 'Unable to save tag. Try again.',
        statusError:
          'No connection found. Check your internet connection and try again.',
      },
      deleteModal: {
        title: 'Delete tag?',
        description:
          'This action will affect all applications where this tag appears. You cannot undo this action.',
        toast: {
          success: 'Tag successfully deleted.',
          error: 'Unable to delete tag. Try again.',
        },
      },
      deleteErrorModal: {
        title: 'Unable to delete tag',
        description:
          'Unassign the tag from all applications before deleting it.',
        button: {
          confirm: 'Got it',
        },
      },
    },
  },
  discardChangesModal: {
    title: 'Discard changes?',
    description: 'All changes made will be lost.',
    goBack: 'No, go back',
    discard: 'Yes, discard',
  },
};

export default enUS;
