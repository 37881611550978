import { lazy, Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import Loader from '../components/Loader/Loader';
import { useAppRoles } from '../hooks/useAppRoles';
import Error403Page from '../pages/Error403Page/Error403Page';
import Error404Page from '../pages/Error404Page/Error404Page';
import FilterContextProvider from '../pages/HomePage/FilterBar/Filter/FilterContext';
import { routes } from './routes';

const CategoriesTagsPage = lazy(
  () => import('../pages/CategoriesTagsPage/CategoriesTagsPage'),
);
const NewCategoryPage = lazy(
  () => import('../pages/NewCategoryPage/NewCategoryPage'),
);
const EditCategoryPage = lazy(
  () => import('../pages/EditCategoryPage/EditCategoryPage'),
);
const EditTagPage = lazy(() => import('../pages/EditTagPage/EditTagPage'));
const NewTagPage = lazy(() => import('../pages/NewTagPage/NewTagPage'));

const NewAppPage = lazy(() => import('../pages/NewAppPage/NewAppPage'));
const HomePage = lazy(() => import('../pages/HomePage/HomePage'));
const AppDetailsPage = lazy(
  () => import('../pages/AppDetailsPage/AppDetailsPage'),
);
const BulkTranslationPage = lazy(
  () =>
    import(
      '../pages/CategoriesTagsPage/BulkTranslationPage/BulkTranslationPage'
    ),
);

const Router = (): JSX.Element => {
  const { isSuperAdmin } = useAppRoles();

  const RestrictedSuperAdminPage = (page: JSX.Element) => {
    if (isSuperAdmin) {
      return <Suspense fallback={<Loader />}>{page}</Suspense>;
    }
    return <Error403Page />;
  };

  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route
          path={routes.HOME_PAGE}
          element={
            <Suspense fallback={<Loader />}>
              <FilterContextProvider>
                <HomePage />
              </FilterContextProvider>
            </Suspense>
          }
        />
        <Route
          path={routes.NEW_APP}
          element={RestrictedSuperAdminPage(<NewAppPage />)}
        />
        <Route
          path={routes.APP_DETAILS_PAGE}
          element={
            <Suspense fallback={<Loader />}>
              <AppDetailsPage />
            </Suspense>
          }
        />
        <Route
          path={routes.CATEGORIES_AND_TAGS}
          element={<Navigate to={routes.CATEGORIES.LIST} replace />}
        />
        {[routes.CATEGORIES.LIST, routes.TAGS.LIST].map((path) => (
          <Route
            key={path}
            path={path}
            element={RestrictedSuperAdminPage(<CategoriesTagsPage />)}
          />
        ))}
        <Route
          path={routes.CATEGORIES.NEW}
          element={RestrictedSuperAdminPage(<NewCategoryPage />)}
        />
        <Route
          path={routes.CATEGORIES.EDIT}
          element={RestrictedSuperAdminPage(<EditCategoryPage />)}
        />
        <Route
          path={routes.TAGS.EDIT}
          element={RestrictedSuperAdminPage(<EditTagPage />)}
        />
        <Route
          path={routes.BULK_TRANSLATION}
          element={RestrictedSuperAdminPage(<BulkTranslationPage />)}
        />
        <Route
          path={routes.TAGS.NEW}
          element={RestrictedSuperAdminPage(<NewTagPage />)}
        />
        <Route path="*" element={<Error404Page />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
