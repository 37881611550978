import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

export interface Filters {
  category: { label: string; value: string }[];
  tag: { label: string; value: string }[];
  responsibleTeam: { label: string; value: string }[];
  status: { label: string; value: string };
  searchValue?: string;
  page?: number;
  pageSize?: number;
}

export interface FiltersContent extends Omit<Filters, 'status'> {
  status: { label: string; value: string }[];
}

export interface FilterProps {
  categoryValues: Filters['category'];
  setCategoryValues: React.Dispatch<React.SetStateAction<Filters['category']>>;
  tagValues: Filters['tag'];
  setTagValues: React.Dispatch<React.SetStateAction<Filters['tag']>>;
  responsibleTeamValues: Filters['responsibleTeam'];
  setResponsibleTeamValues: React.Dispatch<
    React.SetStateAction<Filters['responsibleTeam']>
  >;
  statusValues: Filters['status'];
  setStatusValues: React.Dispatch<React.SetStateAction<Filters['status']>>;
  isApplyDisabled: boolean;
  initializeFilters: (filterValues: Filters) => void;
}
interface ProviderProps {
  children: ReactNode;
  initialFilterValues?: Filters;
}

export const FilterContext = createContext({} as FilterProps);

export const defaultInitialFilterValues = {
  category: [],
  tag: [],
  responsibleTeam: [],
  status: { label: '', value: null },
};

const FilterContextProvider = ({
  children,
  initialFilterValues = defaultInitialFilterValues,
}: ProviderProps): JSX.Element => {
  const [categoryValues, setCategoryValues] = useState<Filters['category']>(
    initialFilterValues['category'],
  );
  const [tagValues, setTagValues] = useState<Filters['tag']>(
    initialFilterValues['tag'],
  );
  const [responsibleTeamValues, setResponsibleTeamValues] = useState<
    Filters['responsibleTeam']
  >(initialFilterValues['responsibleTeam']);
  const [statusValues, setStatusValues] = useState<Filters['status']>(
    initialFilterValues['status'],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initializeFilters = (filterValues: Filters) => {
    setCategoryValues(filterValues.category);
    setTagValues(filterValues.tag);
    setStatusValues(filterValues.status);
    setResponsibleTeamValues(filterValues.responsibleTeam);
  };

  const isApplyDisabled = useMemo(() => {
    return (
      !categoryValues.length &&
      !tagValues.length &&
      !responsibleTeamValues.length &&
      !statusValues.value
    );
  }, [categoryValues, tagValues, responsibleTeamValues, statusValues]);

  const value = useMemo(
    () => ({
      categoryValues,
      setCategoryValues,
      responsibleTeamValues,
      setResponsibleTeamValues,
      tagValues,
      setTagValues,
      statusValues,
      setStatusValues,
      isApplyDisabled,
      initializeFilters,
    }),
    [
      categoryValues,
      setCategoryValues,
      responsibleTeamValues,
      setResponsibleTeamValues,
      tagValues,
      setTagValues,
      statusValues,
      setStatusValues,
      isApplyDisabled,
      initializeFilters,
    ],
  );

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
};

export const useFilter = () => useContext(FilterContext);

export default FilterContextProvider;
