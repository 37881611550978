import { useIntl } from 'react-intl';

import { AppGrid, Tag } from '@hexa-ui/icons';

import { routes } from '../../router/routes';
import { useAppRoles } from '../useAppRoles';

export const sidebarIcons = {
    applications: () => <AppGrid />,
    categoriesAndTags: () => <Tag />,
};

export const useSidebarItems = () => {
    const { formatMessage } = useIntl();
    const { isSuperAdmin } = useAppRoles();

    const items = [
        {
            id: '1',
            title: formatMessage({ id: 'sidebar.applications' }),
            icon: sidebarIcons.applications,
            path: routes.HOME_PAGE,
            visibility: 'all',
        },
        {
            id: '2',
            title: formatMessage({ id: 'sidebar.categoriesAndTags' }),
            icon: sidebarIcons.categoriesAndTags,
            path: routes.CATEGORIES_AND_TAGS,
            visibility: 'superAdmin',
        },
    ];

    const visibleItems = items.filter((item) =>
        isSuperAdmin
            ? item.visibility === 'all' || item.visibility === 'superAdmin'
            : item.visibility === 'all',
    );

    return { visibleItems };
};
